// ==============================
// * PAGE DESIGNER: HERO SLIDER
//
// ? This file gathers all the hero slider CSS.
// ==============================

@import "../../../variables";
@import "../../../abstract/functions";
@import "../../../abstract/mixins";
@import "bootstrap/scss/variables";
@import "bootstrap/scss/mixins/breakpoints";
@import "slider";

.shopthelooks {
    position: relative;
    margin-top: $grid-gutter-width;
    padding-bottom: calc(#{$grid-gutter-width} * 2.667);

    @include media-breakpoint-up(md) {
        margin-top: $grid-gutter-width * 2;
    }

    h2 {
        margin-bottom: calc(#{$grid-gutter-width} / 1.5);

        @include media-breakpoint-up(md) {
            margin-bottom: $grid-gutter-width;
            font-size: $font-size-h1-desktop;
            text-align: center;
        }
    }

    &__cat {
        margin: 0 calc(-#{$grid-gutter-width} / 2) $grid-gutter-width;
        display: flex;
        align-items: center;
        text-transform: uppercase;

        @include media-breakpoint-up(md) {
            margin-bottom: $grid-gutter-width;
            justify-content: center;
        }

        > span {
            margin: 0 $grid-gutter-width / 2;
            cursor: pointer;

            &.active {
                border-bottom: 1.5px solid $black;
            }
        }
    }

    &__container {
        position: relative;

        figure {
            margin-bottom: 0;
        }

        .js-count-items {
            padding-right: calc(#{$grid-gutter-width} / 2);
            text-align: right;

            @include media-breakpoint-up(sm) {
                @include element-centered-Y;

                left: 50px;
                padding-right: 0;
                text-align: initial;
            }
        }
    }

    &__slider {
        padding-top: 0;
    }

    .slick-dots {
        padding-left: 15px;
        bottom: -30px;
    }
}

.js-contain-cat-sliders {
    > div:not(.active) {
        display: none;
    }

    .slick-track {
        > .slick-slide:nth-child(2n + 1) {
            .js-count-items {
                left: initial;
                right: 50px;
            }
        }
    }

    .slick-arrow {
        @include element-centered-Y;

        z-index: 1;

        &.slick-prev {
            left: 5px;
            transform: translateY(-50%) rotate(90deg);
        }

        &.slick-next {
            right: 5px;
            transform: translateY(-50%) rotate(-90deg);
        }
    }

    .slider .slick-slide {
        margin: 0;
    }
}

.no-value-of-items {
    .js-count-items {
        display: none;
    }
}
